:root {
    --panel-width: 320px;
    --panel-offset: var(--panel-width);
}

.libraryPageHome {
    background-color: var(--background-color-level-current);
    display: grid;
    grid-template-columns: 100% var(--panel-offset);
    grid-template-rows: auto 1fr auto;
    min-height: calc(100vh - 44px);

    & > * {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
    }

    & .libraryHeader {
        grid-row: 1 / span 1;
        position: sticky;
        inset-block-start: 0px;
    }

    & .contentWrapper {
        transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
        grid-template-columns: var(--panel-width) 100%;
        padding-block-end: 150px;
        grid-column: 1 / 1;
        grid-row: 2 / span 1;

        @media (width >= 60em) {
            width: var(--breakWidth960);
            max-width: calc(100% - var(--panel-width) - var(--spacing32));
            margin-inline: auto;
        }
    }
}

.gospelLibrarySection {
    display: grid;
    margin-block-start: var(--spacing24);
    position: relative;
    background-color: var(--background-color-level-current);
    flex: 1;

    & .libraryHeader {
        grid-row: 1 / span 1;
        position: sticky;
        inset-block-start: 0px;
    }

    & .librarySEOHead {
        display: flex;
        flex-direction: column;
        gap: var(--spacing32);
        margin-block-end: var(--spacing32);
        padding-inline: var(--spacing16);
    }
}

.heroVideoStream {
    margin-block-end: var(--spacing16);
}

.libraryFooter {
    transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
}

.sidePanelManager {
    grid-column: 2 / span 1;
    grid-row: 2 / span 2;
    transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 25;
    position: sticky;
    inset-block-start: 44px;
}

.sidePanelManagerOpen {
    --inline-end-panel-offset: calc(var(--panel-offset) * -1);

    &:dir(rtl) {
        --inline-end-panel-offset: var(--panel-offset);
    }

    @media (width >= 60em) and (width < 63em) {
        --annotation-toolbar-offset: calc(-7 / 12 * (100vw - 960px) + 28px);

        &:dir(rtl) {
            --annotation-toolbar-offset: calc(7 / 12 * (100vw - 960px) - 28px);
        }
    }
}

.sidePanelManagerOpen {
    @media (width >= 60rem) {
        --centered-offset: calc(var(--inline-end-panel-offset, 0px) / 2);
        --content-title-offset: var(--centered-offset);
        --content-body-offset: var(--centered-offset);
        --next-nav-offset: var(--inline-end-panel-offset);
        --mobile-nav-offset: var(--inline-end-panel-offset);
        --audio-button-offset: var(--inline-end-panel-offset);

        & .libraryFooter {
            transform: translateX(var(--content-body-offset, 0px));
        }
    }

    @media (width >= 80rem) {
        --centered-offset: calc(
            (
                    var(--inline-start-panel-offset, 0px) +
                        var(--inline-end-panel-offset, 0px)
                ) / 2
        );
        --content-title-offset: var(--centered-offset);
        --content-body-offset: var(--centered-offset);
        --icon-group-start-offset: var(--inline-start-panel-offset);
        --prev-nav-offset: var(--inline-start-panel-offset);

        & .libraryFooter {
            transform: translateX(var(--content-body-offset, 0px));
        }
    }

    & .contentWrapper {
        transform: translateX(var(--content-body-offset, 0px));
    }

    & .sidePanelManager {
        transform: translateX(var(--inline-end-panel-offset, 0px));
    }
}

.sidePanelMask[class] {
    z-index: 1;
}

.statusTitle {
    margin-block-end: var(--spacing24);
}

.videoStreamPlaceholder {
    position: relative;
    padding-block-start: 56.25%;
    width: 100%;
    height: 0;
    display: block;
    margin-block-end: var(--spacing10);
}
