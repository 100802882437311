.mediaContainer,
.placeholder {
    display: block;
    margin: 0;
    height: 100%;
    width: 100%;
}

.imageContainer {
    position: relative;
    height: 100%;
}

.image {
    display: block;
    width: 100%;
}

.copyright {
    position: absolute;
    inset-inline-start: var(--spacing8);
    inset-block-end: 0;
    color: var(--textColorSecondary);
    font-size: var(--fontSize10);
}

.posterFrame {
    position: relative;

    & .playButton {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--white);
        background: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        height: var(--spacing60);
        width: var(--spacing60);
        padding: 0.1em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.video {
    width: 100%;
    position: relative;
}

.video iframe {
    position: absolute;
    inset-block-start: 0;
    width: 100%;
    height: 100%;
}

.caption {
    display: block;
    margin: var(--spacing4) 0 0;
    color: var(--textColorSecondary);
    text-align: start;
    font-size: var(--fontSize14);
}

.widthHeightWrapper {
    width: 100%;
    height: 0;
    padding-block-start: 56.25%;
}
