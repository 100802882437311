.LibraryHeader {
    padding: var(--spacing16) var(--spacing24);
    font-size: var(--fontSize14);
    border-block-end: 1px solid var(--borderColorTertiary);
    background: var(--backgroundColorLevelCurrent);
    height: 44px;
    z-index: 20;
    display: flex;
    align-items: center;

    & .link {
        animation: appear 1s ease;
        color: var(--accentColorLink);
        text-decoration: none;
        transition: color 0.3s ease;
        white-space: nowrap;

        & a {
            color: inherit;
        }

        &:empty {
            background: var(--background-secondary, var(--grey5));
            background-size: 22px;
            background-position: 0 0;
            height: 22px;
            width: 10vw;
            position: absolute;

            @media (width >= 37.5em) and (width < 60em) {
                width: 30vw;
            }

            @media (width >= 0em) and (width < 37.5em) {
                width: 60vw;
            }
        }
    }

    & > *:not(:first-child) {
        margin-inline-start: var(--spacing16);
    }

    & .link:not(:first-child) {
        &::before {
            content: "";
            border-inline-start: 1px solid var(--borderColorTertiary);
            padding-inline-end: var(--spacing16);
        }
    }

    & .inactiveLink {
        color: var(--textColorPrimary);

        &:hover {
            text-decoration: none;
        }
    }

    & .bookmarkManager {
        margin-inline-start: auto;
    }

    & .iconGroupEnd {
        display: flex;
        gap: var(--spacing4);
        margin-inline-start: auto;
        height: inherit;

        & .activePanel[class][class] {
            background-color: var(
                --button-navigation-background,
                var(--highlightBlue)
            );
            color: var(--textColorPrimary);
            transition: background-color 0.3s;
            position: relative;
            height: inherit;
            border-radius: unset;
            border-radius: 0;

            &:after {
                height: 4px;
                background-color: var(--accentColorPrimary);
                position: absolute;
                content: "";
                inset-inline: 0;
                inset-block-end: 0;
            }
        }
        & button,
        a {
            padding-inline: var(--spacing8);

            &:hover {
                border-radius: 0;
            }
        }

        & .pipe {
            border-inline-start: 1px solid var(--borderColorTertiary);
            height: 28px;
            margin-block: auto;
            margin-inline: var(--spacing8);
            width: unset;
        }
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
