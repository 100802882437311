.tile {
    display: block;
    color: var(--textColorPrimary);
    text-decoration: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
        text-decoration: none;
        color: var(--textColorPrimary);
    }

    @media (width >= 0em) and (width < 37.5em) {
        padding-block-end: var(--spacing24);
    }

    @media (width >= 37.5em) and (width < 60em) {
        padding-block-end: var(--spacing24);
    }
}

.textContainer {
    padding: 0 var(--spacing4);
}

.mediaContainer {
    position: relative;
    height: 50vw;
    width: 100%;

    @media (width >= 37.5em) {
        height: 25vw;
    }

    @media (width >= 60em) {
        height: 19vw;
    }

    & img {
        height: 100%;
        width: 100%;
        display: block;
        object-fit: cover;
    }

    &.square {
        @media (width >= 60em) {
            height: 0;
            padding-block-start: 100%;

            & figure,
            & .isVideo {
                position: absolute;
                inset-block-start: 0;
            }
        }
    }
}

.isVideo {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    width: 100%;
    height: 100%;

    & .videoIcon {
        background: transparent;
        color: var(--white);
        width: var(--spacing40);
        height: var(--spacing40);
        border: 2px solid var(--white);
        border-radius: 50%;
        padding: 2px;
        box-shadow: inset 0px 0px 15px var(--black), 0px 0px 10px var(--black);

        & svg {
            -webkit-filter: drop-shadow(0px 0px 5px var(--black));
            filter: drop-shadow(0px 0px 5px var(--black));
            padding-inline-start: 3px;
        }
    }
}

.title {
    font-size: var(--fontSize20);
    line-height: var(--lineHeightTight);
    color: var(--accent-color-link);
    font-weight: var(--fontWeightNormal);
    margin: var(--spacing8) 0;
    display: block;

    @media (width >= 0em) and (width < 37.5em) {
        margin-block-end: var(--spacing10);
    }
}

.teaser {
    font-weight: var(--fontWeightLight);
    margin: 0;
    font-size: var(--fontSize14);
    line-height: var(--lineHeightLoose);
}

.overlay {
    position: relative;
    padding-block-end: 0;

    & .textContainer {
        background: linear-gradient(0, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
        background: -moz-linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0)
        );
        color: var(--white);
        font-weight: var(--fontWeightLight);
        inset-block-end: 0;
        inset-inline-start: 0;
        padding: var(--spacing16);
        position: absolute;
        width: 100%;
    }

    & .title {
        font-weight: var(--fontWeightNormal);
        font-style: normal;
        font-size: var(--fontSize20);
        line-height: var(--lineHeightTight);
        margin: 0;
        color: var(--white);
    }

    & .mediaContainer {
        height: 100%;

        & img {
            object-fit: cover;
        }
    }

    & .objectFitFallbackContainer {
        background-position: center;
        background-size: cover;
        overflow: hidden;
    }

    & .teaser {
        display: none;
    }
}

.featured {
    & .title {
        font-size: var(--fontSize22);
        line-height: var(--lineHeightTight);
    }

    & > .teaser {
        line-height: var(--lineHeightTight);
        font-weight: var(--fontWeightLight);
        font-size: var(--fontSize16);
        display: none;

        @media (width >= 37.5em) {
            display: block;
            font-size: var(--fontSize20);
        }
    }
}
