.container {
    position: relative;
    width: 100%;

    & .child {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        height: 100%;
    }
}
