.libraryGridLayout {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    grid-column-gap: var(--spacing16);
    grid-row-gap: var(--spacing32);
    padding-inline: var(--spacing16);

    @media (width >= 0em) and (width < 37.5em) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (width >= 37.5em) and (width < 82em) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.sectionSpacing[class] {
    margin-block-start: var(--spacing40);

    &:first-child {
        margin-block-start: var(--spacing32);
    }
}

/* Tile styles */
.tile {
    /* Parent grid has 6 columns */
    grid-column: span 2;

    @media (width >= 0em) and (width < 37.5em) {
        /* Parent grid has 3 columns */
        grid-column: span 3;
    }

    @media (width >= 37.5em) and (width < 82em) {
        /* Parent grid has 4 columns */
        grid-column: span 2;
    }
}

.spanAllColumns[class] {
    grid-column: span 6;

    @media (width >= 0em) and (width < 37.5em) {
        /* Parent grid has 3 columns */
        grid-column: span 3;
    }

    @media (width >= 37.5em) and (width < 82em) {
        /* Parent grid has 4 columns */
        grid-column: span 4;
    }
}

.search {
    position: relative;

    & input {
        padding-block: var(--spacing8);
        height: 40px;
    }

    @media (width >= 0em) and (width < 37.5em) {
        /* Parent grid has 3 columns */
        grid-column: span 3;
    }

    @media (width >= 37.5em) and (width < 82em) {
        /* Parent grid has 4 columns */
        grid-column: span 2;
    }
}

.constrained {
    @media (width >= 82em) {
        /* Parent grid has 4 columns */
        width: 50%;
    }
}

/* Portrait styles */

.portrait {
    display: block;
    color: var(--textColorPrimary);
    text-decoration: none;
    cursor: pointer;
    position: relative;

    &:hover {
        color: var(--textColorPrimary);
        text-decoration: none;
    }

    /* match the 'sizes' attribute of <Image /> in LibraryGridItem.js */
    @media (width >= 0em) and (width < 37.5em) {
        /* Parent grid has 3 columns */
        grid-column: span 1;
    }

    /* match the 'sizes' attribute of <Image /> in LibraryGridItem.js */
    @media (width >= 37.5em) and (width < 82em) {
        /* Parent grid has 4 columns */
        grid-column: span 1;
    }
}

.portraitContainer {
    position: relative;
}

.portraitGradient:has(svg) {
    /* based on gradient of the now depricated @churchofjesuschrist/eden-media-items */
    background: linear-gradient(
        rgba(46, 53, 56, 0) 0%,
        rgba(46, 53, 56, 0.03) 14.5%,
        rgba(46, 53, 56, 0.176) 36.4%,
        rgba(46, 53, 56, 0.557) 71%,
        rgba(46, 53, 56, 0.9) 100%
    );
    bottom: 0px;
    height: 40%;
    position: absolute;
    width: 100%;
    z-index: 2;

    & .collectionIcon {
        inset-inline-start: 10%;

        &:dir(rtl) {
            transform: scaleX(-1);
        }
    }

    & .restrictedContentIcon {
        inset-inline-end: 10%;
    }

    & svg {
        bottom: 15%;
        position: absolute;
    }
}

.placeholder {
    position: relative;
    background: var(--background-secondary-accent, var(--grey5));
    --rayColor: rgba(255, 255, 255, 0.06);
    background: linear-gradient(
                160deg,
                var(--rayColor) 0 21%,
                transparent 0% 100%
            )
            0px 0 no-repeat,
        linear-gradient(143deg, var(--rayColor) 0 31%, transparent 24% 100%) 0px
            0 no-repeat,
        linear-gradient(195deg, var(--rayColor) 0 23%, transparent 24% 100%) 0px
            0 no-repeat;
    background-color: #006184; /* maybe accentColorPrimary */
}

.portraitImg {
    aspect-ratio: 200 / 267;
    object-fit: cover;
}

.textContainer {
    padding: 0 var(--spacing4);
}

.portraitTitle {
    text-align: center;
    font-weight: var(--fontWeightLight);
    display: block;
    font-size: var(--fontSize14);
    line-height: var(--lineHeight);
    margin-block-start: var(--spacing8);

    @media (width >= 82em) {
        margin-block-start: var(--spacing16);
    }
}
