.tileGroup {
    margin-block-end: var(--spacing10);
}

.title {
    display: block;
    font-size: var(--fontSize14);
    text-transform: uppercase;
    margin: var(--spacing60) 0 0;

    @media (width >= 0em) and (width < 37.5em) {
        margin-block-start: var(--spacing16);
    }
}

/* layout */
.tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tile {
    width: 100%;

    @media (width >= 37.5em) {
        width: 49%;
    }

    @media (width >= 60em) {
        width: 32%;
    }
}

.twoUp .tile {
    @media (width >= 37.5em) {
        width: 49%;
    }
}

.threeUp .tile {
    @media (width >= 37.5em) {
        width: 32%;
    }
}

.fourUp .tile {
    & .mediaContainer {
        height: 20vw;
        border: 1px solid red;
    }

    @media (width >= 37.5em) {
        width: 49%;
    }

    @media (width >= 60em) {
        width: 24%;
        padding-block-end: var(--spacing24);
    }
}

.oneFeature .tile {
    width: 100%;
}

.twoFeature .tile {
    @media (width >= 37.5em) {
        width: 49%;
    }

    @media (width >= 60em) {
        width: 24%;
    }
}

.twoFeature .tile:first-child,
.twoFeature .tile:nth-child(2) {
    width: 100%;

    @media (width >= 60em) {
        width: 49%;
    }
}

.threeFeature {
    margin-block-end: var(--spacing10);

    & .tile {
        width: 100%;
        height: 50vw;
        margin-block-end: 2%;
        padding-block-end: 0;
        border: none;
    }

    & .tile:nth-child(2),
    & .tile:nth-child(3) {
        height: 16.25vw;

        & > .imageContainer {
            height: 100%;
        }
    }

    @media (width >= 37.5em) {
        & .tile {
            width: 49%;
        }

        & .tile:first-child {
            width: 100%;
            margin-block-end: 1vw;
        }

        & .tile:nth-child(2),
        & .tile:nth-child(3) {
            width: 49.5%;
        }
    }

    @media (width >= 60em) {
        position: relative;

        & .tile {
            width: 24.3%;
        }

        & .tile:first-child {
            width: 62.5%;
            margin-inline-end: 0.5%;
            margin-block-end: 0;
        }

        & .tile:nth-child(2),
        & .tile:nth-child(3) {
            width: 37%;
            height: 16.25vw;
            margin-block-end: 0;
        }

        & .tile:nth-child(3) {
            position: absolute;
            inset-inline-end: 0;
            inset-block-end: 0;
        }
    }
}

_:-moz-any-link,
:root .threeFeature {
    & .tile:nth-child(2),
    & .tile:nth-child(3) {
        height: 16vw;
    }
}

@supports ((display: grid) and (grid-template-areas: "a b c")) {
    /* undo what was done above */
    .tiles {
        display: block;
    }

    .tile {
        width: 100%;

        @media (width >= 37.5em) {
            width: 100%;
        }

        @media (width >= 60em) {
            width: 100%;
        }
    }

    .twoUp .tile {
        @media (width >= 37.5em) {
            width: 100%;
        }
    }

    .fourUp .tile {
        & .mediaContainer {
            height: 19vw;
        }

        @media (width >= 37.5em) {
            width: 100%;
        }

        @media (width >= 60em) {
            width: 100%;
        }
    }

    .twoFeature .tile {
        @media (width >= 37.5em) {
            width: 100%;
        }

        @media (width >= 60em) {
            width: 100%;
        }
    }

    .twoFeature .tile:first-child,
    .twoFeature .tile:nth-child(2) {
        width: 100%;

        @media (width >= 60em) {
            width: 100%;
        }
    }

    .threeFeature {
        & .tile {
            width: 100%;
        }

        & .tile:first-child {
            margin-block-end: 0;
        }

        & .tile:nth-child(2),
        & .tile:nth-child(3) {
            margin-block-end: 0;
        }

        @media (width >= 37.5em) {
            & .tile {
                width: 100%;
            }

            & .tile:first-child {
                width: 100%;
            }

            & .tile:nth-child(2),
            & .tile:nth-child(3) {
                width: 100%;
            }
        }
        @media (width >= 60em) {
            position: static;

            & .tile {
                width: 100%;
            }

            & .tile:first-child {
                width: 100%;
                margin-block-end: 0;
            }

            & .tile:nth-child(2),
            & .tile:nth-child(3) {
                width: 100%;
            }

            & .tile:nth-child(3) {
                position: relative;
                inset-inline-end: 0;
                inset-block-start: 0;
            }
        }
    }

    /* now lay it out on the grid */
    .tiles {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: var(--spacing10);

        @media (width >= 37.5em) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (width >= 60em) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .twoUp .tiles {
        grid-template-columns: 1fr;
        grid-template-columns: repeat(2, 1fr);

        @media (width >= 37.5em) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .threeUp .tiles {
        grid-template-columns: 1fr;
        grid-template-columns: repeat(3, 1fr);

        @media (width >= 37.5em) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .fourUp .tiles {
        grid-template-columns: 1fr;

        @media (width >= 37.5em) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (width >= 60em) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .tile {
        width: 100%;
    }

    .twoUp .tile {
        width: 100%;
    }

    .threeUp .tile {
        width: 100%;
    }

    .fourUp .tile {
        width: 100%;
    }

    .oneFeature {
        height: 0;
        position: relative;
        padding-block-end: 37.5%; /* 8X3 ratio */

        & .tiles {
            grid-template-columns: repeat(1, 1fr);
        }

        & .tile {
            grid-column: span 1;
            height: 100%;
            position: absolute;
            inset: 0;
        }
    }

    .twoFeature {
        & .tiles {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(2, 50vw);
        }

        & .tile {
            grid-column: span 1;
            grid-row: span 1;
        }

        & .tile:first-child,
        & .tile:nth-child(2) {
            width: 100%;
            grid-column: span 1;
        }

        @media (width >= 37.5em) {
            & .tiles {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(1, 39.5vw);
            }

            & .tile {
                grid-column: span 1;
            }

            & .tile:first-child,
            & .tile:nth-child(2) {
                width: 100%;
                grid-column: span 1;
            }
        }

        @media (width >= 60em) {
            & .tiles {
                grid-template-rows: repeat(1, 410px);
            }
        }
    }

    .threeFeature {
        & .tiles {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(10, 12vw);
        }

        & .tile {
            display: block;
            width: 100%;
            grid-column: span 1;
        }

        & .tile:first-child {
            grid-column: span 2;
            grid-row: span 4;
            height: 100%;
        }

        & .tile:nth-child(2),
        & .tile:nth-child(3) {
            grid-column: span 2;
            grid-row: span 3;
            height: 100%;

            & > .imageContainer {
                height: 100%;
            }

            & > .title {
                font-size: var(--fontSize18);
            }
        }

        @media (width >= 37.5em) {
            & .tiles {
                grid-template-rows: repeat(5, 15vw);
            }

            & .tile {
                grid-column: span 1;
            }

            & .tile:first-child {
                grid-column: span 2;
                grid-row: span 3;
            }

            & .tile:nth-child(2),
            & .tile:nth-child(3) {
                grid-column: span 1;
                grid-row: span 2;
            }
        }
        @media (width >= 60em) {
            & .tiles {
                grid-template-rows: repeat(2, 200px);
            }

            & .tile {
                grid-column: span 1;
            }

            & .tile:nth-child(2),
            & .tile:nth-child(3) {
                grid-column: span 1;
                grid-row: span 1;

                & > .teaser {
                    display: none;
                }
            }
            & .tile:first-child {
                grid-column: span 1;
                grid-row: span 2;
            }
        }
    }
}

.additionalTiles {
    & .title {
        margin: 0;
    }

    & > .teaser {
        display: none;
    }
}
